import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationModule } from './authorization/authorization.module';
import { AuthGuard } from './core/guards/auth.guard';
import { AppRoutes } from './app-routes';
import { SuperAdminLayoutComponent } from './super-admin-layout/super-admin-layout.component';
import { UserRole } from './core';
import { LayoutComponent } from './layout/layout.component';
import { SignUpConfirmModule } from './pages/sign-up-confirm';
import { ForgotModule, ResetModule } from './reset-forgot-password';
import { ClientSignUpConfirmModule } from './pages/client-sign-up-confirm';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./redirect').then((m) => m.RedirectModule),
  },
  {
    path: 'superadmin',
    component: SuperAdminLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.SUPER_ADMIN] },
    children: [
      {
        path: '',
        redirectTo: `/superadmin/${AppRoutes.DASHBOARD}`,
        pathMatch: 'full',
      },
      {
        path: AppRoutes.DASHBOARD,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: AppRoutes.CLIENT_ADMINS,
        loadChildren: () =>
          import('./pages/client-admins/client-admins.module').then(
            (m) => m.ClientAdminsModule,
          ),
      },
      {
        path: AppRoutes.CLIENTS,
        loadChildren: () =>
          import('./pages/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: AppRoutes.BUILDINGS,
        loadChildren: () =>
          import('./pages/buildings/buildings.module').then(
            (m) => m.BuildingsModule,
          ),
      },
      {
        path: AppRoutes.LIFTS,
        loadChildren: () =>
          import('./pages/lifts/lifts.module').then((m) => m.LiftsModule),
      },
      {
        path: AppRoutes.DEVICES,
        loadChildren: () =>
          import('./pages/devices/devices.module').then((m) => m.DevicesModule),
      },
    ],
  },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.CLIENT_ADMIN] },
    children: [
      {
        path: '',
        redirectTo: `/admin/${AppRoutes.DASHBOARD}`,
        pathMatch: 'full',
      },
      {
        path: AppRoutes.DASHBOARD,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: AppRoutes.CLIENTS,
        loadChildren: () =>
          import('./pages/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: AppRoutes.BUILDINGS,
        loadChildren: () =>
          import('./pages/buildings/buildings.module').then(
            (m) => m.BuildingsModule,
          ),
      },
      {
        path: AppRoutes.LIFTS,
        loadChildren: () =>
          import('./pages/lifts/lifts.module').then((m) => m.LiftsModule),
      },
    ],
  },

  {
    path: 'client',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.CLIENT] },
    children: [
      {
        path: '',
        redirectTo: `/client/${AppRoutes.DASHBOARD}`,
        pathMatch: 'full',
      },
      {
        path: AppRoutes.DASHBOARD,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: AppRoutes.BUILDINGS,
        loadChildren: () =>
          import('./pages/buildings/buildings.module').then(
            (m) => m.BuildingsModule,
          ),
      },
      {
        path: AppRoutes.LIFTS,
        loadChildren: () =>
          import('./pages/lifts/lifts.module').then((m) => m.LiftsModule),
      },
    ],
  },
  {
    path: `${AppRoutes.AUTH}`,
    loadChildren: () =>
      import('./authorization/authorization.module').then(
        (m) => AuthorizationModule,
      ),
  },
  {
    path: `${AppRoutes.FORGOT}`,
    loadChildren: () =>
      import('./reset-forgot-password').then((m) => ForgotModule),
  },
  {
    path: `${AppRoutes.RESET}/:token`,
    loadChildren: () =>
      import('./reset-forgot-password').then((m) => ResetModule),
  },
  {
    path: `${AppRoutes.VERIFY}/:token`,
    loadChildren: () =>
      import('./pages/sign-up-confirm/sign-up-confirm.module').then(
        (m) => SignUpConfirmModule,
      ),
  },
  {
    path: `${AppRoutes.VERIFY}/clients/:token`,
    loadChildren: () =>
      import(
        './pages/client-sign-up-confirm/client-sign-up-confirm.module'
      ).then((m) => ClientSignUpConfirmModule),
  },
  {
    path: 'v',
    loadChildren: () => import('./pages/v/v.module').then(m => m.VModule)
  },
  { path: '**', redirectTo: `/` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
